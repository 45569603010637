import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGEX, SALESPERSON } from './constants';

const initialValues = {
  email: '',
  salesperson: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .matches(EMAIL_REGEX, 'Enter a valid email address')
});

const GoToCalcForm = ({ handleGoToCalcSubmit }) => {
  const handleSubmit = (values) => {
    handleGoToCalcSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form className="go-to-calc-form">
            <div className="form-wrapper">
              <label htmlFor="email">Email*</label>
              <Field
                type="email"
                name="email"
                className="form-control formik-input"
              />
              <ErrorMessage name="email" component="div" className="error" />
              <div className="input-wrapper">
                <label htmlFor="salesperson">
                  Salesperson working with (optional)
                </label>
                <Field
                  type="text"
                  name="salesperson"
                  className="form-control formik-input"
                  list="saleperson-list"
                />
                <datalist id="saleperson-list">
                  {SALESPERSON.map((person) => (
                    <option key={person} value={person} />
                  ))}
                </datalist>
                <ErrorMessage
                  name="salesperson"
                  component="div"
                  className="error"
                />
              </div>
              <div className="btn-wrapper">
                <button
                  type="submit"
                  className="btn btn-primary go-to-calc-btn"
                >
                  Go to Calculator
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GoToCalcForm;
