import {
  STATES_DATA,
  REFINANCE_CLOSING_COSTS,
  BUYER_SELLER_CLOSING_COSTS
} from './constants';

export const fetchStates = () => {
  //TODO: place the actual API call to fetch states data
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: STATES_DATA
      });
    }, 500);
  });
};

export const getCostEstimates = async (values) => {
  //TODO: place the actual API call to submit form data and fetch closing costs
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return {
    success: true,
    data:
      values.type === 'refinance'
        ? REFINANCE_CLOSING_COSTS
        : BUYER_SELLER_CLOSING_COSTS
  };
};

export const emailEstimateCost = async (email) => {
  //TODO: place the actual API call to send estimate over email
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return {
    success: true,
    message: 'Email sent successfully!!'
  };
};
