import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { COST_DISCLAIMER, EMAIL_REGEX } from './constants';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .matches(EMAIL_REGEX, 'Enter a valid email address')
});

const ConfirmationModal = ({
  show,
  handleToggle,
  handleSubmit,
  modalTitle,
  modalButtonTitle,
  type,
  emailValue,
  closingCosts
}) => {
  useEffect(() => {
    // Disable body scroll when modal is open
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    return () => {
      // Re-enable body scroll when modal is closed
      document.body.style.overflow = 'auto';
      document.body.style.position = '';
    };
  }, []);

  return (
    <div>
      <div className="modal-overlay" />
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        tabIndex="-1"
        style={{ display: show ? 'block' : 'none' }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleToggle}
              ></button>
            </div>
            <Formik
              initialValues={{
                email: emailValue
              }}
              validationSchema={validationSchema}
              validateOnChange={true}
              onSubmit={(values) => handleSubmit(values.email)}
            >
              {() => {
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="form-floating">
                        <Field
                          placeholder="Email*"
                          type="email"
                          name="email"
                          className="form-control form-control-flush"
                        />
                        <label htmlFor="email">Email*</label>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="modal-cost-wrapper">
                        {type !== 'refinance' && (
                          <>
                            <div className="closing-cost-items">
                              <div>
                                <span>Buyer Fees</span>
                                <h5 className="sub-total-closing-cost total-closing-cost">
                                  ${closingCosts?.buyer_total_cost}
                                </h5>
                              </div>
                              <div>
                                <span>Seller Fees</span>
                                <h5 className="sub-total-closing-cost total-closing-cost">
                                  ${closingCosts?.seller_total_cost}
                                </h5>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}
                        <h3>Estimated closing cost</h3>
                        <h5 className="total-closing-cost">
                          ${closingCosts?.total_cost}
                        </h5>
                        <p className="cost-disclaimer">{COST_DISCLAIMER}</p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-dark">
                        {modalButtonTitle}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
