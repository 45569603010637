export const COST_DISCLAIMER =
  'These costs are estimates only, allocated to buyer and seller based on local "customs". However, the parties are always free to allocate these contractually in any manner of their choosing.';

export const CALC_DISCLAIMER =
  "Disclaimer: This estimate is based on the information provided and is intended for general reference only. It should not be relied upon as the sole source for determining final costs. Please note that not all potential fees are included, and the selection of additional services or optional policies, such as an owner’s policy, may affect the final amount. Additional policies and services may also be available. For a comprehensive and accurate assessment, we recommend consulting with your salesperson. If you notice any discrepancies or inaccuracies in this calculator, please notify us so we can address them promptly. Please contact our processing team for additional information on endorsements and additional policy options.  As well, please note that an Owner's Policy is always optional and is not required to close with Presidential.";

// Regular expression for email validation (basic format check)
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$/;

export const STATES_DATA = [
  {
    state_name: 'Abc',
    state_code: 'AB',
    is_active: false
  },
  {
    state_name: 'California',
    state_code: 'CA',
    is_active: true,
    counties: [
      {
        county_name: 'Los Angeles County',
        county_code: '06037',
        cities: [
          {
            city_name: 'Los Angeles',
            city_code: '0600000'
          },
          {
            city_name: 'Long Beach',
            city_code: '0604000'
          }
        ]
      },
      {
        county_name: 'San Francisco County',
        county_code: '06075',
        cities: [
          {
            city_name: 'San Francisco',
            city_code: '0608000'
          }
        ]
      }
    ]
  },
  {
    state_name: 'Texas',
    state_code: 'TX',
    is_active: true,
    counties: [
      {
        county_name: 'Harris County',
        county_code: '48201',
        cities: [
          {
            city_name: 'Houston',
            city_code: '4820100'
          }
        ]
      },
      {
        county_name: 'Dallas County',
        county_code: '48113',
        cities: [
          {
            city_name: 'Dallas',
            city_code: '4811300'
          }
        ]
      }
    ]
  },
  {
    state_name: 'New York',
    state_code: 'NY',
    is_active: true,
    counties: [
      {
        county_name: 'New York County',
        county_code: '36061',
        cities: [
          {
            city_name: 'New York City',
            city_code: '3601000'
          }
        ]
      },
      {
        county_name: 'Kings County',
        county_code: '36047',
        cities: [
          {
            city_name: 'Brooklyn',
            city_code: '3604000'
          }
        ]
      }
    ]
  }
];

export const REFINANCE_CLOSING_COSTS = {
  total_cost: '20,123',
  costs: [
    {
      label: 'Closing cost 1',
      value: 123.45
    },
    {
      label: 'Closing cost 2',
      value: 123.45
    },
    {
      label: 'Closing cost 3',
      value: 123.45
    },
    {
      label: 'Closing cost 4',
      value: 123.45
    },
    {
      label: 'Closing cost 5',
      value: 123.45
    },
    {
      label: 'Closing cost 6',
      value: 123.45
    }
  ]
};

export const BUYER_SELLER_CLOSING_COSTS = {
  total_cost: '40,246',
  buyer_total_cost: '1,523.45',
  seller_total_cost: '2,123.45',
  costs: [
    {
      label: 'Closing cost 1',
      buyer_cost: 123.45,
      seller_cost: 123.45
    },
    {
      label: 'Closing cost 2',
      buyer_cost: 123.45,
      seller_cost: 123.45
    },
    {
      label: 'Closing cost 3',
      buyer_cost: 0,
      seller_cost: 123.45
    },
    {
      label: 'Closing cost 4',
      buyer_cost: 123.45,
      seller_cost: 123.45
    },
    {
      label: 'Closing cost 5',
      buyer_cost: 0,
      seller_cost: 123.45
    },
    {
      label: 'Closing cost 6',
      buyer_cost: 123.45,
      seller_cost: 0
    }
  ]
};

export const SALESPERSON = [
  'Tom Olsund (tom@presidentialtitlegroup.com)',
  'Elite Closing (elite@presidentialtitlegroup.com)',
  'Seller McBrokerson (mc@presidentialtitlegroup.com)'
];
