import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { fetchStates, getCostEstimates, emailEstimateCost } from './fetch';
import { COST_DISCLAIMER, CALC_DISCLAIMER } from './constants';
import ConfirmationModal from './confirmation-modal';
import SuccessModal from './success-modal';

const initialValues = {
  state: '',
  county: '',
  city: '',
  type: '',
  purchasePrice: '',
  loanAmount: '',
  policy: '',
  additionalInfo1: '',
  additionalInfo2: []
};

const checkCurrencyRange = (amount) => {
  // Remove $ and commas and ensure the result is a valid number
  const cleanedValue = amount.replace(/[^0-9.-]+/g, '');

  // Convert to number
  const numValue = parseFloat(cleanedValue);

  // Check if the value is a valid number
  if (isNaN(numValue)) {
    return false;
  }

  // Check if within the range
  return numValue >= 1 && numValue <= 999999999;
};

const calcValidationSchema = Yup.object().shape({
  state: Yup.string().required('State is required'),
  county: Yup.string().required('County is required'),
  city: Yup.string().required('City is required'),
  type: Yup.string().required('Type is required'),
  purchasePrice: Yup.string().when('type', {
    is: (value) => value !== 'refinance',
    then: (schema) =>
      schema
        .required('Purchase price is required')
        .test(
          'is-valid-currency',
          'Purchase price should be within the range of 1 to 999999999.',
          (value) => {
            return checkCurrencyRange(value);
          }
        ),
    otherwise: (schema) => schema
  }),
  loanAmount: Yup.string().when('type', {
    is: (value) => value !== 'cash/purchase',
    then: (schema) =>
      schema
        .required('Loan amount is required')
        .test(
          'is-valid-currency',
          'Loan amount should be within the range of 1 to 999999999.',
          (value) => {
            return checkCurrencyRange(value);
          }
        ),
    otherwise: (schema) => schema
  }),
  additionalInfo1: Yup.string().required('This is required')
});

const CalcForm = ({ emailValue, salespersonValue, handleGoToCalcSubmit }) => {
  const [closingCosts, setClosingCosts] = useState();
  const [loadingCosts, setLoadingCosts] = useState(false);
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedStateActive, setSelectedStateActive] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [emailToValue, setEmailToValue] = useState('');
  const [selectedType, setSelectedType] = useState('');

  // Fetch dropdown options
  useEffect(() => {
    const fetchStatesData = async () => {
      try {
        const response = await fetchStates();
        setStates(response.data);
      } catch (err) {
        console.error('Failed to load states', err);
      }
    };

    fetchStatesData();
  }, []);

  const handleConfirmationModalToggle = () =>
    setConfirmationModalShow(!confirmationModalShow);

  const handleSuccessModalToggle = () => setSuccessModalShow(!successModalShow);

  const handleConfirmationModalSubmit = async (emailTo) => {
    try {
      const response = await emailEstimateCost(emailTo);
      if (response.success) {
        setEmailToValue(emailTo);
        handleConfirmationModalToggle();
        setSuccessModalShow(true);
      }
    } catch (error) {
      console.error('error while sending email', error);
    }
  };

  const handleSuccessModalSubmit = () => {
    handleGoToCalcSubmit('');
  };

  const handleCalcSubmit = async (values) => {
    setLoadingCosts(true);
    try {
      const response = await getCostEstimates(values);
      if (response.success) {
        setClosingCosts(response.data);
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error('error while submitting the form', error);
    } finally {
      setLoadingCosts(false);
    }
  };

  const handleStateChange = (stateValue, resetForm, setFieldValue) => {
    setFormSubmitted(false);
    resetForm(); // Reset the form to initial values
    const state = states.find((state) => state.state_code === stateValue);
    setFieldValue('state', stateValue);
    if (state.is_active) {
      setSelectedStateActive(true);
      setCounties(state.counties);
      setCities([]); // Reset cities on state change
    } else {
      setSelectedStateActive(false);
      setFieldValue('state', stateValue);
      setCounties([]);
      setCities([]); // Reset cities on state change
    }
  };

  // Handle county change
  const handleCountyChange = (selectedCounty, setFieldValue) => {
    setFieldValue('county', selectedCounty);
    const county = counties.find(
      (county) => county.county_code === selectedCounty
    );
    setCities(county.cities);
  };

  const handleTypeChange = (typeValue, setFieldValue) => {
    //on type change reset everything
    setSelectedType(typeValue);
    setFieldValue('type', typeValue);
    setClosingCosts();
    setFormSubmitted(false);
    setFieldValue('loanAmount', '');
    setFieldValue('purchasePrice', '');
  };

  const formatCurrency = (value) => {
    // Remove all non-numeric characters except decimal point
    const numericValue = String(value).replace(/[^0-9.]/g, '');

    // If empty string or invalid number, return empty or 0
    if (!numericValue) return '$0';

    const number = parseFloat(numericValue);
    if (isNaN(number)) return '$0';

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(number);
  };

  const handlePriceChange = (e, setFieldValue) => {
    const { value, name } = e.target;
    const formattedValue = formatCurrency(value);
    setFieldValue(name, formattedValue);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={calcValidationSchema}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
      >
        {(props) => {
          const { values, isValid, dirty, setFieldValue, resetForm } = props;
          const { state, county, type, policy } = values;

          const getPriceValueNumber = (priceValue) => {
            return Number(priceValue.replace(/[^0-9.-]+/g, ''));
          };

          //logic to handle form submission once all fields are filled and validated
          useEffect(() => {
            // Get required fields based on current values
            const getRequiredFields = () => {
              const baseRequired = [
                'state',
                'county',
                'city',
                'type',
                'policy',
                'additionalInfo1'
              ];
              if (values.type == 'refinance') {
                return [...baseRequired, 'loanAmount'];
              }
              if (values.type == 'cash/purchase') {
                return [...baseRequired, 'purchasePrice'];
              }
              if (values.type == 'loan/purchase') {
                return [...baseRequired, 'purchasePrice', 'loanAmount'];
              }
              return baseRequired;
            };

            // Check if all required fields are filled
            const requiredFields = getRequiredFields();
            const requiredFieldsFilled = requiredFields.every(
              (field) => values[field] !== ''
            );

            const purchasePriceValue = requiredFields.includes('purchasePrice')
              ? getPriceValueNumber(values.purchasePrice) > 0
              : true;
            const loanAmountValue = requiredFields.includes('loanAmount')
              ? getPriceValueNumber(values.loanAmount) > 0
              : true;

            if (
              isValid &&
              dirty &&
              requiredFieldsFilled &&
              purchasePriceValue &&
              loanAmountValue
            ) {
              handleCalcSubmit(values);
            }
          }, [values, isValid, dirty]);

          const getTypeBtnClasses = (btnValue) => {
            let btnClass = 'btn-outline-primary';
            if (!selectedStateActive) btnClass = 'btn-secondary';
            else if (type === btnValue) btnClass = 'btn-primary';
            return btnClass;
          };

          return (
            <Form>
              <div className="calc-form-wrapper">
                <div className="form-wrapper">
                  <div className="input-wrapper">
                    <label htmlFor="state">State*</label>
                    <Field
                      as="select"
                      name="state"
                      className="form-control option formik-input"
                      onChange={(e) =>
                        handleStateChange(
                          e.target.value,
                          resetForm,
                          setFieldValue
                        )
                      }
                    >
                      <option value="">Select a state</option>
                      {states.map((state) => (
                        <option key={state.state_code} value={state.state_code}>
                          {state.state_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="error"
                    />
                  </div>
                  {counties.length > 0 && (
                    <div className="input-wrapper">
                      <label htmlFor="county">County*</label>
                      <Field
                        as="select"
                        name="county"
                        className="form-control formik-input option"
                        onChange={(e) =>
                          handleCountyChange(e.target.value, setFieldValue)
                        }
                        disabled={!state}
                      >
                        <option value="">Select a county</option>
                        {counties.map((county) => (
                          <option
                            key={county.county_code}
                            value={county.county_code}
                          >
                            {county.county_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="county"
                        component="div"
                        className="error"
                      />
                    </div>
                  )}
                  {cities.length > 0 && (
                    <div className="input-wrapper">
                      <label htmlFor="city">City*</label>
                      <Field
                        as="select"
                        name="city"
                        className="form-control formik-input option"
                        onChange={(e) => setFieldValue('city', e.target.value)}
                        disabled={!county}
                      >
                        <option value="">Select a city</option>
                        {cities.map((city) => (
                          <option key={city.city_code} value={city.city_code}>
                            {city.city_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                  )}
                  <>
                    <label htmlFor="type" className="type-label">
                      Choose type:
                    </label>
                    <div
                      role="group"
                      aria-labelledby="type"
                      className="btn-wrapper btn-group-wrapper"
                    >
                      <button
                        type="button"
                        disabled={!selectedStateActive}
                        className={`btn go-to-calc-btn ${getTypeBtnClasses(
                          'cash/purchase'
                        )}`}
                        onClick={() =>
                          handleTypeChange('cash/purchase', setFieldValue)
                        }
                      >
                        Cash/Purchase
                      </button>
                      <button
                        type="button"
                        disabled={!selectedStateActive}
                        className={`btn go-to-calc-btn ${getTypeBtnClasses(
                          'loan/purchase'
                        )}`}
                        onClick={() =>
                          handleTypeChange('loan/purchase', setFieldValue)
                        }
                      >
                        Loan/Purchase
                      </button>
                      <button
                        type="button"
                        disabled={!selectedStateActive}
                        className={`btn go-to-calc-btn 
                          ${getTypeBtnClasses('refinance')}`}
                        onClick={() =>
                          handleTypeChange('refinance', setFieldValue)
                        }
                      >
                        Refinance
                      </button>
                    </div>
                  </>
                  {type && (
                    <>
                      {type !== 'refinance' && (
                        <div className="input-wrapper">
                          <label htmlFor="purchasePrice">Purchase Price*</label>
                          <Field
                            type="text"
                            name="purchasePrice"
                            className="form-control formik-input"
                            onChange={(e) =>
                              handlePriceChange(e, setFieldValue)
                            }
                          />
                          <ErrorMessage
                            name="purchasePrice"
                            component="div"
                            className="error"
                          />
                        </div>
                      )}
                      {type !== 'cash/purchase' && (
                        <div className="input-wrapper">
                          <label htmlFor="loanAmount">Loan Amount*</label>
                          <Field
                            type="text"
                            name="loanAmount"
                            className="form-control formik-input"
                            onChange={(e) =>
                              handlePriceChange(e, setFieldValue)
                            }
                          />
                          <ErrorMessage
                            name="loanAmount"
                            component="div"
                            className="error"
                          />
                        </div>
                      )}
                      <>
                        <label htmlFor="policy" className="type-label">
                          <i
                            className="fe fe-info text-gray-500"
                            data-bs-toggle="tooltip"
                            title="Placeholder to explain what ALTA & Home Owners policies are about"
                          />
                          Owners policy:
                        </label>
                        <div
                          role="group"
                          aria-labelledby="policy"
                          className="btn-wrapper btn-group-wrapper"
                        >
                          <button
                            type="button"
                            disabled={false}
                            className={`btn go-to-calc-btn ${
                              policy === 'alta'
                                ? 'btn-primary'
                                : 'btn-outline-primary'
                            }`}
                            onClick={() => setFieldValue('policy', 'alta')}
                          >
                            ALTA
                          </button>
                          <button
                            type="button"
                            disabled={false}
                            className={`btn go-to-calc-btn ${
                              policy === 'home owners'
                                ? 'btn-primary'
                                : 'btn-outline-primary'
                            }`}
                            onClick={() =>
                              setFieldValue('policy', 'home owners')
                            }
                          >
                            Home Owners
                          </button>
                        </div>
                      </>
                      <div className="input-wrapper">
                        <label className="type-label">
                          Additional Information:
                        </label>
                        <div className="question-wrapper">
                          <span className="question-no">1.</span>
                          <div>
                            <label htmlFor="additionalInfo1">
                              Really long question with a yes or no answer that
                              is required to continue*
                            </label>
                            <div className="radio-container">
                              <label>
                                <Field
                                  type="radio"
                                  name="additionalInfo1"
                                  value="yes"
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="additionalInfo1"
                                  value="no"
                                />
                                No
                              </label>
                            </div>
                            <ErrorMessage
                              name="additionalInfo1"
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                        <div className="question-wrapper">
                          <span className="question-no">2.</span>
                          <div>
                            <label htmlFor="additionalInfo2">
                              Really long question and you can select multiple
                              answers
                            </label>
                            <div className="radio-container">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="additionalInfo2"
                                  value="1"
                                />
                                This is the option 1
                              </label>
                              <label>
                                <Field
                                  type="checkbox"
                                  name="additionalInfo2"
                                  value="2"
                                />
                                Option 2
                              </label>
                              <label>
                                <Field
                                  type="checkbox"
                                  name="additionalInfo2"
                                  value="3"
                                />
                                Option 3
                              </label>
                              <label>
                                <Field
                                  type="checkbox"
                                  name="additionalInfo2"
                                  value="4"
                                />
                                Option 4 goes here, yay!
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="form-wrapper">
                  {!formSubmitted ? (
                    <>
                      {!selectedStateActive && state != '' ? (
                        <p>
                          Unfortunately, we’re not available in your state just
                          yet. However, we’re expanding and we’ll keep you
                          updated as soon as we arrive in your area.
                        </p>
                      ) : (
                        <>
                          <p>
                            Provide the required details in the form to unlock a
                            detailed estimate breakdown below.
                          </p>
                          <hr />
                          <div className="costs-wrapper">
                            {[1, 2, 3, 4, 5, 6].map((closingCost) => (
                              <div
                                key={closingCost}
                                className="closing-cost-items empty-items"
                              />
                            ))}
                            <div className="closing-cost-items empty-items total-empty-item" />
                            <p className="cost-disclaimer">{COST_DISCLAIMER}</p>
                          </div>

                          <div className="btn-wrapper">
                            <button
                              type="button"
                              disabled={true}
                              className="btn go-to-calc-btn btn-success"
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p>
                        Here’s a summary of the estimated costs associated with
                        this prospective transaction. Please note that these
                        figures may vary based on your specific circumstances
                        and should be considered as estimates, as outlined in
                        the disclaimer below.
                      </p>
                      <hr />
                      <div
                        className={`costs-wrapper real-costs-wrapper ${
                          type !== 'refinance' ? 'flex-wrap-container' : ''
                        }`}
                      >
                        {loadingCosts ? (
                          <h5>Loading....</h5>
                        ) : (
                          <>
                            {type === 'refinance' ? (
                              <div className="buyer-seller-costs">
                                <h4 className="cost-title">Refinance Fees</h4>
                                {closingCosts?.costs?.map((closingCost) => (
                                  <div
                                    key={closingCost.label}
                                    className="closing-cost-items"
                                  >
                                    <span className="cost-label">
                                      {closingCost.label}
                                    </span>
                                    <span className="sub-costs">
                                      ${closingCost.value}
                                    </span>
                                  </div>
                                ))}
                                <hr />
                              </div>
                            ) : (
                              <div className="buyer-seller-costs">
                                <div className="closing-cost-items">
                                  <h4 className="cost-cell"></h4>
                                  <h4 className="cost-title cost-cell">
                                    Buyer Fees
                                    <hr />
                                  </h4>
                                  <h4 className="cost-title cost-cell">
                                    Seller Fees
                                    <hr />
                                  </h4>
                                </div>
                                {closingCosts?.costs?.map((closingCost) => (
                                  <div
                                    key={closingCost.label}
                                    className="closing-cost-items"
                                  >
                                    <span className="cost-cell cost-label">
                                      {closingCost.label}
                                    </span>
                                    <span className="cost-cell sub-costs">
                                      ${closingCost.buyer_cost}
                                    </span>
                                    <span className="cost-cell sub-costs">
                                      ${closingCost.seller_cost}
                                    </span>
                                  </div>
                                ))}
                                <div className="closing-cost-items">
                                  <h5 className="cost-cell"></h5>
                                  <h5 className="cost-cell total-closing-cost sub-total-closing-cost">
                                    <hr />${closingCosts?.buyer_total_cost}
                                  </h5>
                                  <h5 className="cost-cell total-closing-cost sub-total-closing-cost">
                                    <hr />${closingCosts?.seller_total_cost}
                                  </h5>
                                </div>
                                <hr />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <h5 className="total-closing-cost cost-cell">
                        ${closingCosts?.total_cost}
                      </h5>
                      <p className="cost-disclaimer">{COST_DISCLAIMER}</p>
                      <hr />
                      <div className="submit-wrapper">
                        <span>Submit to send a copy to your email</span>
                        <button
                          type="button"
                          className="btn go-to-calc-btn btn-success"
                          onClick={handleConfirmationModalToggle}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <p className="disclaimer">{CALC_DISCLAIMER}</p>

      {confirmationModalShow && (
        <ConfirmationModal
          show={confirmationModalShow}
          handleToggle={handleConfirmationModalToggle}
          handleSubmit={handleConfirmationModalSubmit}
          modalTitle="Confirm or edit email"
          modalButtonTitle="Submit"
          type={selectedType}
          emailValue={emailValue}
          closingCosts={closingCosts}
        />
      )}
      {successModalShow && (
        <SuccessModal
          show={successModalShow}
          handleToggle={handleSuccessModalToggle}
          handleSubmit={handleSuccessModalSubmit}
          modalTitle="Success!"
          modalButtonTitle="Close"
          emailToValue={emailToValue}
          salesperson={salespersonValue}
        />
      )}
    </>
  );
};

export default CalcForm;
