import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import Header from './header';
import GoToCalcForm from './go-to-calc-form';
import CalcForm from './calc-form';

const Calculator = () => {
  const [email, setEmail] = useState('');
  const [salesperson, setSalesperson] = useState('');
  const [showNextForm, setShowNextForm] = useState(false);

  const handleGoToCalcSubmit = ({ email, salesperson }) => {
    setShowNextForm(!showNextForm);
    setEmail(email);
    setSalesperson(salesperson);
  };

  return (
    <main className="calculator">
      <Header emailValue={email} handleGoToCalcSubmit={handleGoToCalcSubmit} />
      {!showNextForm ? (
        <GoToCalcForm handleGoToCalcSubmit={handleGoToCalcSubmit} />
      ) : (
        <CalcForm
          emailValue={email}
          salespersonValue={salesperson}
          handleGoToCalcSubmit={handleGoToCalcSubmit}
        />
      )}
    </main>
  );
};

const root = createRoot(document.getElementById('calculator-root'));
root.render(<Calculator />);
