import React, { useEffect } from 'react';

const SuccessModal = ({
  show,
  handleToggle,
  handleSubmit,
  modalTitle,
  modalButtonTitle,
  emailToValue,
  salesperson
}) => {
  useEffect(() => {
    // Disable body scroll when modal is open
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';

    return () => {
      // Re-enable body scroll when modal is closed
      document.body.style.overflow = 'auto';
      document.body.style.position = '';
    };
  }, []);

  return (
    <div>
      <div className="modal-overlay" />
      <div
        className={`modal fade ${show ? 'show' : ''}`}
        tabIndex="-1"
        style={{ display: show ? 'block' : 'none' }}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleToggle}
              ></button>
            </div>
            <div className="modal-body success-modal-body">
              <p>
                Your estimate has been sent to:{' '}
                <span className="email-value">{emailToValue}</span>
              </p>
              {!!salesperson && (
                <p>
                  A copy was also emailed to{' '}
                  <span className="email-value">{salesperson}</span>
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-dark"
                onClick={handleSubmit}
              >
                {modalButtonTitle}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
